var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status_board_cns",class:{
    process_progress_status: _vm.tabIndex == 0,
    status_board_cns_theme: _vm.tabIndex != 0,
    request_order_status: _vm.tabIndex == 2,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToStatusMonitorPage',"options":[
          { title: '공정진행 현황', name: 'process_status' },
          { title: '완제품 재고현황', name: 'finished_stock_status' },
          { title: '출고지시서', name: 'request_order_status' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),(_vm.tabIndex == 0)?_c('button',{staticClass:"btn_admin",on:{"click":_vm.FETCH_PRODUCTION_LIST}},[_vm._v(" 새로고침 "+_vm._s(_vm.timer)+" ")]):_vm._e(),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("공정진행 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("완제품 재고현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("출고지시서")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('process-status-form',{key:_vm.key,on:{"changetimer":function($event){return _vm.changeTimer($event)}}}):_vm._e(),(_vm.tabIndex == 1)?_c('finished-stock-status-form'):_vm._e(),(_vm.tabIndex == 2)?_c('request-order-status-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }